import {immerable} from 'immer';
import {Entity} from '@computerrock/formation-core';
import {Address} from '../address';
import {Bank} from '../bank';
import {Location} from '../location';

/**
 * Member entity type definition
 *
 * @typedef {Object} Member
 * @property {?string} email
 * @property {?string} type
 * @property {?string} status
 * @property {?string} name
 * @property {?string} firstname
 * @property {?string} surname
 * @property {?string} membershipNumber
 * @property {?string} phoneNumber
 * @property {?Address} address
 * @property {?Bank} bank
 * @property {?Location} location
 */

/**
 * Member entity-to-DTO mapping
 *
 * @type {Object<string, EntityDTOProperty>}
 */
const memberInfoEntityDTOMapping = {
    email: {
        dtoProperty: 'email',
        defaultValue: '',
    },
    type: {
        dtoProperty: 'type',
        defaultValue: '',
    },
    status: {
        dtoProperty: 'status',
        defaultValue: '',
    },
    name: {
        dtoProperty: 'name',
        defaultValue: '',
    },
    firstname: {
        dtoProperty: 'firstName',
        defaultValue: '',
    },
    surname: {
        dtoProperty: 'surname',
        defaultValue: '',
    },
    membershipNumber: {
        dtoProperty: 'membershipNumber',
        defaultValue: '',
    },
    phoneNumber: {
        dtoProperty: 'phoneNumber',
        defaultValue: '',
    },
    address: {
        dtoProperty: 'address',
        defaultValue: new Address(),
        entity: Address,
        toPatchDTO: value => {
            if (value && Object.keys(value).length > 0) {
                return {value: Address.objectToDTO(value)};
            }
            return {value: null};
        },
    },
    bank: {
        dtoProperty: 'bank',
        defaultValue: new Bank(),
        entity: Bank,
    },
    location: {
        dtoProperty: 'location',
        entity: Location,
        toPatchDTO: value => {
            if (value && Object.keys(value).length > 0) {
                return {value: Location.objectToDTO(value)};
            }
            return {value: null};
        },
    },
};

/**
 * ServiceCase entity
 *
 * @class
 * @extends Entity
 * @type {Member}
 */
export default class Member extends Entity {
    [immerable] = true;

    static entityDTOMapping = memberInfoEntityDTOMapping;

    /**
     * @constructor
     * @param rawObject
     */
    constructor(rawObject = {}) {
        super();
        this.initialize(rawObject);
    }
}
