import {fork, put, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import {push, resolveRoute} from '@computerrock/formation-router';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import modalIds from '../../modalIds';
import routePaths from '../../routePaths';
import {personTypes} from '../constants/person';

const deleteInvoiceSubmissionDraftFlow = function* deleteInvoiceSubmissionDraftFlow() {
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');

    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.INITIATE_DELETE_INVOICE_SUBMISSION_DRAFT_FLOW);
        const {isNewServiceCase, serviceCaseId} = payload;

        yield* openModal(modalIds.DISCARD_DRAFT_MODAL);

        const chosenModalOption = yield take([
            invoiceSubmissionActionTypes.CONFIRM_DELETE_INVOICE_SUBMISSION_DRAFT,
            invoiceSubmissionActionTypes.DECLINE_DELETE_INVOICE_SUBMISSION_DRAFT,
        ]);

        const {invoiceSubmissionDraft} = yield select(state => state.invoiceSubmissions);

        if (chosenModalOption.type === invoiceSubmissionActionTypes.DECLINE_DELETE_INVOICE_SUBMISSION_DRAFT) {
            const {payload} = chosenModalOption;
            const {isServiceCasesScreen} = payload;
            yield* closeModal(modalIds.DISCARD_DRAFT_MODAL);

            if (isServiceCasesScreen) {
                yield put(push(!invoiceSubmissionDraft.serviceCaseId.includes('MIA')
                    ? resolveRoute(routePaths.INVOICE_SUBMISSION_CREATION, {
                        serviceCaseId: invoiceSubmissionDraft.serviceCaseId,
                        invoiceSubmissionId: invoiceSubmissionDraft.invoiceSubmissionId,
                    })
                    : resolveRoute(routePaths.NEW_INVOICE_SUBMISSION_CREATION, {
                        invoiceSubmissionId: invoiceSubmissionDraft.invoiceSubmissionId,
                    })));
            }
            continue;
        }

        if (!invoiceSubmissionDraft) {
            yield* closeModal(modalIds.DISCARD_DRAFT_MODAL);
            continue;
        }

        yield fork(fetchRequest,
            invoiceSubmissionActionTypes.DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST,
            miaService.deleteInvoiceSubmission, {
                invoiceSubmissionId: invoiceSubmissionDraft?.invoiceSubmissionId,
            });

        const deleteInSDraftResponseAction = yield take([
            invoiceSubmissionActionTypes.DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.DELETE_INVOICE_SUBMISSION_DRAFT_REQUEST_FAILED,
        ]);

        if (deleteInSDraftResponseAction.error) {
            // TBD: handle errors (try-again, close the modal, etc.)
            yield* closeModal(modalIds.DISCARD_DRAFT_MODAL);
            continue;
        }

        // remove InS draft from the state
        yield put({
            type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_DRAFT,
            payload: {invoiceSubmissionDraft: null},
        });

        // close the modal
        yield* closeModal(modalIds.DISCARD_DRAFT_MODAL);

        // create new InS draft if user selected existing service case
        if (serviceCaseId) {
            const {memberInfo} = yield select(state => state.member);
            const {serviceCases} = yield select(state => state.serviceCases);
            const {email, phoneNumber} = serviceCases[serviceCaseId].member;
            const {firstname, surname, bank, status, ...restMemberValues} = serviceCases[serviceCaseId].member;

            yield put({
                type: invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_DRAFT,
                payload: {
                    invoiceSubmissionDraftData: {
                        serviceCaseId,
                        prefix: serviceCases[serviceCaseId]?.prefix,
                        vehicle: serviceCases[serviceCaseId]?.vehicle,
                        member: {
                            ...restMemberValues,
                            name: [firstname, surname].filter(value => !!value).join(' '),
                            type: personTypes.MEMBER,
                            email: email || memberInfo?.email || '',
                            phoneNumber: phoneNumber || memberInfo?.phoneNumber || '',
                        },
                    },
                },
            });
            continue;
        }

        // redirect to the service cases screen or to invoice creation screen
        yield put(push(resolveRoute(isNewServiceCase
            ? routePaths.NEW_INVOICE_SUBMISSION_CREATION
            : routePaths.SERVICE_CASES)));
    }
};

export default deleteInvoiceSubmissionDraftFlow;
