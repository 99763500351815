/**
 * @typedef {string} ServiceType
 */

/**
 * Service type
 *
 * @enum {ServiceType}
 */

export default {
    ACCOMMODATION: 'ACCOMMODATION',
    BROKEN_GLASS: 'BROKEN_GLASS',
    FURTHER_JOURNEY: 'FURTHER_JOURNEY',
    MARTEN_BITE: 'MARTEN_BITE',
    ROADKILL: 'ROADKILL',
    ELEMENTAL_DAMAGE: 'ELEMENTAL_DAMAGE',
    OTHER: 'OTHER',
    RECOVERY: 'RECOVERY',
    RENTAL_CAR: 'RENTAL_CAR',
    ROADSIDE_ASSISTANCE: 'ROADSIDE_ASSISTANCE',
    SHORT_TRIP: 'SHORT_TRIP',
    TOWING: 'TOWING',
    VEHICLE_OPENING: 'VEHICLE_OPENING',
};
