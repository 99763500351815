import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, Modal, Icon, infoIcon, ButtonSecondary, ButtonPrimary} from '../../ui-components';
import {ContentBlock, ContentItem} from '../../ui-components/app-layout';
import {Circle} from '../../ui-components/general';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import routePaths from '../../routePaths';

const DiscardDraftModal = props => {
    const {cx} = useStyles();
    const {hasBackdrop, confirmInvoiceSubmissionDraftDeletion, declineInvoiceSubmissionDraftDeletion} = props;
    const {location} = props;
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('discard_draft_modal');
    const isServiceCasesScreen = location?.pathname === routePaths.SERVICE_CASES;

    return (
        <Modal
            contentClassName={cx('global!ace-u-flex', 'global!ace-u-flex--justify-center')}
            hasBackdrop={hasBackdrop}
            closeFunction={declineInvoiceSubmissionDraftDeletion}
        >
            <ContentBlock
                className={cx([
                    'global!ace-u-flex',
                    'global!ace-u-flex--direction-column',
                    'global!ace-u-flex--justify-center',
                ])}
            >
                <ContentItem
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-flex--direction-column',
                    ])}
                >
                    <Circle
                        className={cx([
                            'ace-c-circle--small',
                            'global!ace-u-margin--bottom-24',
                        ])}
                    >
                        <Icon
                            icon={infoIcon}
                            className={cx([
                                'ace-c-icon--24',
                                'ace-c-icon--color-highlight',
                            ])}
                        />
                    </Circle>
                    <h2
                        className={cx([
                            'global!ace-u-margin--bottom-16',
                            'global!ace-u-typography--variant-h2',
                            'global!ace-u-typography--align-center',
                        ])}
                    >
                        {translateModal(isServiceCasesScreen ? 'title.edit_draft' : 'title.discard_draft')}
                    </h2>
                    <p
                        className={cx([
                            'global!ace-u-margin--bottom-24',
                            'global!ace-u-typography--align-center',
                            'global!ace-u-typography--variant-body',
                        ])}
                    >
                        {translateModal(isServiceCasesScreen ? 'text.edit_draft' : 'text.discard_draft')}
                    </p>
                    {!isServiceCasesScreen
                        ? (
                            <>
                                <ButtonPrimary
                                    name="cancelInvoiceSubmission"
                                    className={cx([
                                        'global!ace-u-full-width',
                                        'global!ace-u-margin--small-bottom-16',
                                    ])}
                                    onClick={confirmInvoiceSubmissionDraftDeletion}
                                >
                                    {translateModal('button_label.yes')}
                                </ButtonPrimary>
                                <ButtonSecondary
                                    className={cx('global!ace-u-full-width')}
                                    onClick={() => declineInvoiceSubmissionDraftDeletion({isServiceCasesScreen})}
                                >
                                    {translateModal('button_label.cancel')}
                                </ButtonSecondary>
                            </>
                        )
                        : (
                            <>
                                <ButtonPrimary
                                    name="cancelInvoiceSubmissionDraftDeletion"
                                    className={cx([
                                        'global!ace-u-full-width',
                                        'global!ace-u-margin--small-bottom-16',
                                    ])}
                                    onClick={() => declineInvoiceSubmissionDraftDeletion({isServiceCasesScreen})}
                                >
                                    {translateModal('button_label.continue')}
                                </ButtonPrimary>
                                <ButtonSecondary
                                    name="cancelInvoiceSubmission"
                                    className={cx('global!ace-u-full-width')}
                                    onClick={confirmInvoiceSubmissionDraftDeletion}
                                >
                                    {translateModal('button_label.new')}
                                </ButtonSecondary>
                            </>
                        )}
                </ContentItem>
            </ContentBlock>
        </Modal>
    );
};

DiscardDraftModal.propTypes = {
    hasBackdrop: PropTypes.bool,
    confirmInvoiceSubmissionDraftDeletion: PropTypes.func.isRequired,
    declineInvoiceSubmissionDraftDeletion: PropTypes.func.isRequired,
    location: PropTypes.object,
};

DiscardDraftModal.defaultProps = {
    hasBackdrop: true,
    location: null,
};

const mapDispatchToProps = dispatch => ({
    confirmInvoiceSubmissionDraftDeletion: payload => dispatch({
        type: invoiceSubmissionActionTypes.CONFIRM_DELETE_INVOICE_SUBMISSION_DRAFT,
        payload,
    }),
    declineInvoiceSubmissionDraftDeletion: payload => dispatch({
        type: invoiceSubmissionActionTypes.DECLINE_DELETE_INVOICE_SUBMISSION_DRAFT,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(DiscardDraftModal);
