import React, {Fragment, useContext, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {useParams, useRouter} from '@computerrock/formation-router';
import {plusIcon} from '@ace-de/ui-components';
import ServiceCategoryTitle from '../../ui-components/general/ServiceCategoryTitle';
import {categories, categoryIcons} from '../constants/categories';
import {ButtonGhost, ButtonGhostContent, documentIcon, Form, InputField, paymentIcon, StepperContext, useStyles} from '../../ui-components';
import AccordionRow from '../../ui-components/general/AccordionRow';
import SectionDetails from '../../ui-components/general/SectionDetails';
import {services} from '../constants/services';
import {personTypes} from '../constants/person';
import {formNames, formStepNames} from '../constants/forms';
import {maskIban} from '../utils/conversion';
import {emailValidation, phoneNumberValidation} from '../utils/validation';
import {journeyContinuationServices} from '../constants/journeyContinuationServices';
import FormButtons from './FormButtons';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {serviceTypes} from '../../mia-entities';
import modalIds from '../../modalIds';
import {mafInvoiceSubmissionStatuses} from '../../mia-entities/invoice-submission';

const groupServicesByCategory = services => {
    if (!services || services.length === 0) return null;
    const sortedServices = {};
    services.forEach((service, index) => {
        switch (service.type) {
            case serviceTypes.BROKEN_GLASS:
            case serviceTypes.MARTEN_BITE:
            case serviceTypes.ROADKILL:
            case serviceTypes.ELEMENTAL_DAMAGE: {
                if (!sortedServices[categories.AID]) {
                    sortedServices[categories.AID] = [{...service, index}];
                    break;
                }
                sortedServices[categories.AID].push({...service, index});
                break;
            }
            case serviceTypes.ACCOMMODATION:
            case serviceTypes.FURTHER_JOURNEY:
            case serviceTypes.RENTAL_CAR:
            case serviceTypes.SHORT_TRIP: {
                if (!sortedServices[categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION]) {
                    sortedServices[categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION] = [{...service, index}];
                    break;
                }
                sortedServices[categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION].push({...service, index});
                break;
            }
            case serviceTypes.OTHER: {
                if (!sortedServices[categories.OTHER]) {
                    sortedServices[categories.OTHER] = [{...service, index}];
                    break;
                }
                sortedServices[categories.OTHER].push({...service, index});
                break;
            }
            case serviceTypes.ROADSIDE_ASSISTANCE:
            case serviceTypes.TOWING:
            case serviceTypes.VEHICLE_OPENING: {
                if (!sortedServices[categories.ROADSIDE_ASSISTANCE_AND_TOWING]) {
                    sortedServices[categories.ROADSIDE_ASSISTANCE_AND_TOWING] = [{...service, index}];
                    break;
                }
                sortedServices[categories.ROADSIDE_ASSISTANCE_AND_TOWING].push({...service, index});
                break;
            }
        }
    });
    return sortedServices;
};

const SummaryScreen = props => {
    const {cx} = useStyles();
    const {openModal} = useRouter();
    const {submitInvoiceSubmission, invoiceSubmissionDraft, storeInvoiceSubmissionDraft, memberInfo} = props;
    const {cancelInvoiceSubmissionDraft, initiateDeleteInvoiceSubmissionServiceFlow} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateScreen = createTranslateShorthand('summary_screen');
    const {selectStep, currentStep} = useContext(StepperContext);
    const params = useParams();
    const isFromExistingCase = !!params.serviceCaseId;
    const sortedServices = useMemo(() => (
        groupServicesByCategory(invoiceSubmissionDraft?.services)
    ), [invoiceSubmissionDraft?.services]);
    const [isValidEmail, setIsValidEmail] = useState(invoiceSubmissionDraft?.member?.email || memberInfo?.email
        ? emailValidation(invoiceSubmissionDraft?.member?.email || memberInfo?.email) : true);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(
        invoiceSubmissionDraft?.member?.phoneNumber || memberInfo?.phoneNumber
            ? phoneNumberValidation(invoiceSubmissionDraft?.member?.phoneNumber || memberInfo?.phoneNumber)
            : true,
    );

    if (!invoiceSubmissionDraft) return null;

    const {attachmentsDetails, invoicingRemarks, paymentInfo} = invoiceSubmissionDraft;

    const getFormOverlay = category => {
        switch (category) {
            case categories.ROADSIDE_ASSISTANCE_AND_TOWING:
                return formNames.ROADSIDE_ASSISTANCE_AND_TOWING;
            case categories.AID:
                return formNames.AID;
            case categories.OTHER:
                return formNames.OTHER;
            case categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION:
                return formNames.JOURNEY_CONTINUATION_AND_ACCOMMODATION;
            default:
                return '';
        }
    };

    const composeFullAddress = address => {
        if (!address || typeof address !== 'object') return '';
        return Object.keys(address).map(key => {
            if (!address[key]
                || address[key] === ''
                || key === 'formattedAddress'
                || key === 'persistenceState'
                || key === 'location'
            ) return;
            return address[key];
        }).filter(value => !!value).join(', ');
    };

    const handleAddService = () => {
        storeInvoiceSubmissionDraft({
            invoiceSubmissionDraft: {
                ...invoiceSubmissionDraft,
                services: [
                    ...invoiceSubmissionDraft.services,
                    {},
                ],
            },
        });
        selectStep(invoiceSubmissionDraft.services.length + (isFromExistingCase ? 1 : 2));
    };

    const isMemberDataValid = memberData => {
        // the member's email is mandatory
        if (!memberData.email) return false;
        // validate member's email and phoneNumber
        if (!emailValidation(memberData.email)) return false;
        if (!phoneNumberValidation(memberData.phoneNumber)) return false;

        return true;
    };

    const handleOnSubmit = formValues => {
        const memberData = formValues?.member && Object.values(formValues.member)?.length > 0
            ? formValues.member : {email: memberInfo?.email, phoneNumber: memberInfo?.phoneNumber};
        if (!isMemberDataValid(memberData)) return;
        const invoiceSubmissionData = {
            member: {
                ...(memberData || {}),
            },
        };
        submitInvoiceSubmission({
            invoiceSubmissionData,
            invoiceSubmissionId: invoiceSubmissionDraft.invoiceSubmissionId,
            statusData: {status: mafInvoiceSubmissionStatuses.CREDITING_REQUESTED},
        });
    };

    const handleOnClose = formValues => {
        const memberData = formValues?.member && Object.values(formValues.member)?.length > 0
            ? formValues.member : {email: memberInfo?.email, phoneNumber: memberInfo?.phoneNumber};
        if (!isMemberDataValid(memberData)) return;
        const invoiceSubmissionDraftData = {
            member: {
                ...(memberData || {}),
            },
            services: invoiceSubmissionDraft.services,
        };
        cancelInvoiceSubmissionDraft({invoiceSubmissionDraftData, currentStep});
    };

    return (
        <Form name="summary" onSubmit={handleOnSubmit}>
            {formValues => (
                <Fragment>
                    <h2
                        className={cx([
                            'global!ace-u-typography--variant-h2',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                    >
                        {translateScreen('title.summary')}
                    </h2>
                    {sortedServices && Object.keys(sortedServices).map(key => (
                        <Fragment key={key}>
                            <ServiceCategoryTitle
                                className={cx(['global!ace-u-margin--small-bottom-24'])}
                                icon={categoryIcons[key]}
                            >
                                {translate(`global.service_category.${key?.toLowerCase()}`)}
                            </ServiceCategoryTitle>
                            {sortedServices[key].map(service => (
                                <AccordionRow
                                    key={`${service}-${service.index}`}
                                    title={translate(`global.service_type.${service.type?.toLowerCase()}`)}
                                    collapsedTitle={`${translate(`global.service_type.${service.type?.toLowerCase()}`)} - ${Number(service.requestedInvoicingAmount || 0)?.toFixed(2).toString().replace('.', ',')} €`}
                                    className={cx('global!ace-u-margin--small-bottom-24')}
                                    isDefaultOpenState={false}
                                >
                                    <SectionDetails
                                        className={cx('global!ace-u-margin--small-bottom-24')}
                                        onEditDetails={() => openModal(modalIds.EDIT_INVOICE_SUBMISSION_MODAL, {
                                            type: getFormOverlay(key),
                                            serviceIndex: service.index.toString(),
                                        })}
                                        onDelete={() => initiateDeleteInvoiceSubmissionServiceFlow({
                                            serviceIndex: service.index,
                                            selectStep,
                                            step: currentStep,
                                        })}
                                        isDeletable={service.index > 0}
                                    >
                                        <p
                                            className={cx([
                                                'global!ace-u-typography--variant-body-medium',
                                                'global!ace-u-margin--small-bottom-8',
                                            ])}
                                        >
                                            {translateScreen('text.performance')}
                                        </p>
                                        <p>
                                            {`${translateScreen('text.amount_requested')} ${Number(service.requestedInvoicingAmount)?.toFixed(2).toString().replace('.', ',')} €`}
                                        </p>
                                        {key === categories.AID && (
                                            <p>
                                                {`${translateScreen('text.type_of_aid')} ${translate(`global.service_type.${service.type?.toLowerCase()}`) || '/'}`}
                                            </p>
                                        )}
                                        {(key === categories.AID
                                            || key === categories.ROADSIDE_ASSISTANCE_AND_TOWING) && (
                                            <Fragment>
                                                <p className={cx('global!ace-u-typography--variant-body-medium')}>{translateScreen('text.driver')}</p>
                                                <p>{`${translateScreen('text.first_name')} ${service.driver?.name || '/'}`}</p>
                                                <p>{`${translateScreen('text.surname')} ${service.driver?.surname || '/'}`}
                                                </p>
                                                {service.driver?.type !== personTypes.MEMBER && (
                                                    <Fragment>
                                                        <p>{`${translateScreen('text.street')} ${service.driver?.address?.street || '/'}`}</p>
                                                        <p>{`${translateScreen('text.postcode')} ${service.driver?.address?.postCode || '/'}`}</p>
                                                        <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                                            {`${translateScreen('text.location')} ${service.driver?.address?.city || '/'}`}
                                                        </p>
                                                    </Fragment>
                                                )
                                                }
                                                <p className={cx('global!ace-u-typography--variant-body-medium')}>{translateScreen('text.holder')}</p>
                                                <p>{`${translateScreen('text.first_name')} ${service.owner?.name || '/'}`}</p>
                                                <p>{`${translateScreen('text.surname')} ${service.owner?.surname || '/'}`}</p>
                                                {service.owner?.type !== personTypes.MEMBER && (
                                                    <Fragment>
                                                        <p>{`${translateScreen('text.street')} ${service.owner?.address?.street || '/'}`}</p>
                                                        <p>{`${translateScreen('text.postcode')} ${service.owner?.address?.postCode || '/'}`}</p>
                                                        <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                                            {`${translateScreen('text.location')} ${service.owner?.address?.city || '/'}`}
                                                        </p>
                                                    </Fragment>
                                                )
                                                }
                                                <p className={cx('global!ace-u-typography--variant-body-medium')}>{translateScreen('text.case_data')}</p>
                                                <p>{`${translateScreen('text.purpose_of_the_journey')} ${translate(`global.purpose.${service.tripPurpose?.toLowerCase()}`)}`}</p>
                                                <p>{`${translateScreen('text.location_of_damage')} ${composeFullAddress(service.damage?.address)}`}
                                                </p>
                                                <p>{`${translateScreen('text.damage_date')} ${
                                                    service.damage?.date
                                                        ? moment(service.damage.date, moment.ISO_8601).format('DD.MM.YYYY')
                                                        : '/'
                                                }`}
                                                </p>
                                                {(service.type === services.TOWING) && (
                                                    <p>{`${translateScreen('text.towing_location')} ${composeFullAddress(service.towing?.address)}`}
                                                    </p>
                                                )}
                                                <p>{`${translateScreen('text.service_provider')} ${service.serviceProvider || '/'}`}</p>
                                                <p>
                                                    {`${translateScreen('text.end_of_recovery_date_timer')} ${service.endOfRecoveryDateTime
                                                        ? moment(service.endOfRecoveryDateTime, moment.ISO_8601).format('DD.MM.YYYY')
                                                        : '/'}`}
                                                </p>
                                            </Fragment>
                                        )}
                                        {key === categories.ROADSIDE_ASSISTANCE_AND_TOWING && (
                                            <Fragment>
                                                <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                                    {`${translateScreen('text.number_of_passengers')} ${service.numberOfPassengers || '0'}`}
                                                </p>
                                                <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                                    {`${translateScreen('text.damage_description')} ${service.comment || '/'}`}
                                                </p>
                                                <p className={cx('global!ace-u-typography--variant-body-medium')}>{`${translateScreen('text.police_accident')} ${service.isAccident ? '/' : ': Nein'}`}</p>
                                                {service.isAccident && (
                                                    <Fragment>
                                                        <p>{`${translateScreen('text.is_recorded_by_police')} ${service.isRecordedByPolice ? translateScreen('text.yes') : translateScreen('text.no')}`}</p>
                                                        {service.isRecordedByPolice && (
                                                            <Fragment>
                                                                <p>{`${translateScreen('text.police_department')} ${service.policeDepartment}`}</p>
                                                                <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                                                    {`${translateScreen('text.police_record_number')} ${service.policeRecordNumber || '/'}`}
                                                                </p>
                                                            </Fragment>
                                                        )}
                                                        <p>{`${translateScreen('text.accident_caused_by_third_party')}
                                                    ${service.thirdParty?.isCausedAccident
                                                            ? translateScreen('text.yes')
                                                            : translateScreen('text.no')}`}
                                                        </p>
                                                        {service.thirdParty?.isCausedAccident && (
                                                            <Fragment>
                                                                <p>{`${translateScreen('text.surname')} ${service.thirdParty?.name || '/'}`}</p>
                                                                <p>{`${translateScreen('text.street')} ${service.thirdParty?.address?.street || '/'}`}</p>
                                                                <p>{`${translateScreen('text.postcode')} ${service.thirdParty?.address?.postCode || '/'}`}</p>
                                                                <p>{`${translateScreen('text.location')} ${service.thirdParty?.address?.city || '/'}`}</p>
                                                                <p>{`${translateScreen('text.license_plate')} ${service.thirdParty?.licensePlate || '/'}`}</p>
                                                                <p>{`${translateScreen('text.insurance')} ${service.thirdParty?.insuranceName || '/'}`}</p>
                                                                <p>{`${translateScreen('text.third_party_insurance_number')} ${service.thirdParty?.insuranceNumber || '/'}`}</p>
                                                                <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                                                    {`${translateScreen('text.third_party_damage_number')} ${service.thirdParty?.damageNumber || '/'}`}
                                                                </p>
                                                            </Fragment>
                                                        )}
                                                    </Fragment>
                                                )}
                                                <p>{`${translateScreen('text.is_alcohol_test_done')} ${service.isAlcoholTestDone ? translateScreen('text.yes') : translateScreen('text.no')}`}</p>
                                                {service.isAlcoholTestDone && (
                                                    <p className={cx('global!ace-u-margin--small-bottom-8')}>{`${translateScreen('text.alcohol_test_result')} ${service.alcoholTestResult || '/'}`}</p>
                                                )}

                                                <p>{`${translateScreen('text.is_insurance_covers_costs')} ${service.isInsuranceCoversCosts ? translateScreen('text.yes') : translateScreen('text.no')}`}</p>
                                                {service.isInsuranceCoversCosts && (
                                                    <Fragment>
                                                        <p>{`${translateScreen('text.insurance_name')} ${service.insuranceName || '/'}`}</p>
                                                        <p className={cx('global!ace-u-margin--small-bottom-8')}>{`${translateScreen('text.insurance_number')} ${service.insuranceNumber || '/'}`}</p>
                                                    </Fragment>
                                                )}
                                                <p>{`${translateScreen('text.is_third_party_cost')} ${service.thirdParty?.isCoveringCosts ? translateScreen('text.yes') : translateScreen('text.no')}`}</p>
                                                {service.thirdParty?.isCoveringCosts && (
                                                    <Fragment>
                                                        <p>{`${translateScreen('text.third_party_costs_covered_by')} ${service.thirdParty?.costsCoveredBy || '/'}`}</p>
                                                        <p>{`${translateScreen('text.third_party_costs_purpose')} ${service.thirdParty?.costsPurpose || '/'}`}</p>
                                                        <p className={cx('global!ace-u-margin--small-bottom-8')}>
                                                            {`${translateScreen('text.third_party_costs_amount')} ${service.thirdParty?.costsAmount || '/'}`}
                                                        </p>
                                                    </Fragment>
                                                )}
                                                <p>{`${translateScreen('text.drivers_license_valid')} ${service.isDriversLicenseValid ? translateScreen('text.yes') : translateScreen('text.no')}`}</p>
                                            </Fragment>
                                        )}
                                        {(key === categories.OTHER || key === categories.AID) && (
                                            <p>{`${translateScreen('text.what_happened')} ${service.comment || '/'}`}</p>
                                        )}
                                        {key === categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION && (
                                            <Fragment>
                                                <p>
                                                    {`${translateScreen('text.service_type')} ${translate(`global.service_type.${service.type?.toLowerCase()}`) || '/'}`}
                                                </p>
                                                {(service.type === serviceTypes.SHORT_TRIP
                                                    || service.type === serviceTypes.FURTHER_JOURNEY) && (
                                                    <Fragment>
                                                        <p>
                                                            {`${translateScreen('text.journey_date')} ${service.startDateTime
                                                                ? moment(service.startDateTime, moment.ISO_8601).format('DD.MM.YYYY')
                                                                : '/'}`}
                                                        </p>
                                                        <p>
                                                            {`${translateScreen('text.from')} ${typeof service.startingAddress !== 'object'
                                                                ? service.startingAddress
                                                                : composeFullAddress(service.startingAddress)
                                                            }`}
                                                        </p>
                                                        <p>
                                                            {`${translateScreen('text.to')} ${typeof service.destinationAddress !== 'object'
                                                                ? service.destinationAddress
                                                                : composeFullAddress(service.destinationAddress)
                                                            }`}
                                                        </p>
                                                        <p>
                                                            {`${translateScreen('text.notes')} ${service.comment || '/'}`}
                                                        </p>
                                                    </Fragment>
                                                )}
                                                {service.type === journeyContinuationServices.ACCOMMODATION && (
                                                    <Fragment>
                                                        <p>
                                                            {`${translateScreen('text.from')} ${
                                                                service.startDateTime
                                                                    ? moment(service.startDateTime, moment.ISO_8601).format('DD.MM.YYYY') // eslint-disable-line max-len
                                                                    : '/'
                                                            }`}
                                                        </p>
                                                        <p>
                                                            {`${translateScreen('text.to')} ${
                                                                service.endDateTime
                                                                    ? moment(service.endDateTime, moment.ISO_8601).format('DD.MM.YYYY')
                                                                    : '/'
                                                            }`}
                                                        </p>
                                                        <p>
                                                            {`${translateScreen('text.number_of_people')} ${service.numberOfPeople || '/'}`}
                                                        </p>
                                                        <p>
                                                            {`${translateScreen('text.surname')} ${service.name || '/'}`}
                                                        </p>
                                                        <p>
                                                            {`${translateScreen('text.notes')} ${service.description || '/'}`}
                                                        </p>
                                                    </Fragment>
                                                )}
                                                {service.type === serviceTypes.RENTAL_CAR && (
                                                    <Fragment>
                                                        <p>{translateScreen('text.period_of_rental')}</p>
                                                        <p>
                                                            {`${translateScreen('text.from')} ${
                                                                service.startDateTime
                                                                    ? moment(service.startDateTime, moment.ISO_8601).format('DD.MM.YYYY')// eslint-disable-line max-len
                                                                    : '/'
                                                            }`}
                                                        </p>
                                                        <p>{`${translateScreen('text.to')} ${
                                                            service.endDateTime
                                                                ? moment(service.endDateTime, moment.ISO_8601).format('DD.MM.YYYY')
                                                                : '/'
                                                        }`}
                                                        </p>
                                                        <p>
                                                            {`${translateScreen('text.pickup_location')} ${composeFullAddress(service.startingAddress)}`}
                                                        </p>
                                                        <p>
                                                            {`${translateScreen('text.return_location')} ${composeFullAddress(service.destinationAddress)}`}
                                                        </p>
                                                        <p>
                                                            {`${translateScreen('text.is_vehicle_delivered')} ${service.isDelivered ? translateScreen('text.yes') : translateScreen('text.no')}`}
                                                        </p>
                                                        <p>
                                                            {`${translateScreen('text.notes')} ${service.comment || '/'}`}
                                                        </p>
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        )}
                                    </SectionDetails>
                                </AccordionRow>
                            ))}
                        </Fragment>
                    ))}
                    <Fragment>
                        <ButtonGhost
                            className={cx('global!ace-u-margin--bottom-16')}
                            onClick={handleAddService}
                        >
                            <ButtonGhostContent
                                icon={plusIcon}
                                iconPosition="left"
                                className={cx('ace-c-icon--color-highlight')}
                            >
                                {translateScreen('button_label.add_service')}
                            </ButtonGhostContent>
                        </ButtonGhost>
                        <ServiceCategoryTitle
                            className={cx(['global!ace-u-margin--small-bottom-24'])}
                            icon={documentIcon}
                        >
                            {translateScreen('title.documents')}
                        </ServiceCategoryTitle>
                        <SectionDetails
                            className={cx([
                                'ace-c-section-details--bordered',
                                'global!ace-u-padding--small-16',
                                'global!ace-u-margin--bottom-24',
                            ])}
                            onEditDetails={() => openModal(modalIds.EDIT_INVOICE_SUBMISSION_MODAL, {
                                type: formNames.ATTACHMENTS,
                            })}
                        >
                            <p>{translateScreen('text.documents')}</p>
                            {(attachmentsDetails?.attachments || []).map(({fileName, id}) => {
                                return (
                                    <p key={id}>{fileName}</p>
                                );
                            })}
                            <p>{`${translateScreen('text.remarks')} ${invoiceSubmissionDraft.invoicingRemarks || invoicingRemarks || '/'}`}</p>
                        </SectionDetails>
                        <ServiceCategoryTitle className={cx(['global!ace-u-margin--small-bottom-24'])} icon={paymentIcon}>
                            {translateScreen('title.bank_details')}
                        </ServiceCategoryTitle>
                        <SectionDetails
                            className={cx([
                                'ace-c-section-details--bordered',
                                'global!ace-u-padding--small-16',
                            ])}
                            onEditDetails={() => openModal(modalIds.EDIT_INVOICE_SUBMISSION_MODAL, {
                                type: formNames.BANK_INFO,
                            })}
                        >
                            <p>{`${translateScreen('text.surname')} ${paymentInfo.bankAccountOwner}`}</p>
                            <p>{`${translateScreen('text.iban')} ${maskIban(paymentInfo.iban)}`}</p>
                            <p>{`${translateScreen('text.bic_swift')} ${paymentInfo.swift || '/'}`}</p>
                        </SectionDetails>
                    </Fragment>
                    <Form name="member">
                        {formValues => {
                            return (
                                <Fragment>
                                    <h2
                                        className={cx([
                                            'global!ace-u-typography--variant-h2',
                                            'global!ace-u-margin--small-bottom-24',
                                            'global!ace-u-margin--small-top-24',
                                        ])}
                                    >
                                        {translateScreen('title.contact_details')}
                                    </h2>
                                    <InputField
                                        name="email"
                                        label={translateScreen('input_label.email')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--large-bottom-32',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                        value={invoiceSubmissionDraft?.member?.email || ''}
                                        onBlur={() => setIsValidEmail(formValues.email
                                            ? emailValidation(formValues.email) : false)}
                                        errors={!isValidEmail ? [translate('global.error.invalid_email')] : []}
                                    />
                                    <InputField
                                        name="phoneNumber"
                                        label={translateScreen('input_label.phone_number')}
                                        className={cx('global!ace-u-full-width')}
                                        value={invoiceSubmissionDraft?.member?.phoneNumber || ''}
                                        onBlur={() => {
                                            setIsValidPhoneNumber(phoneNumberValidation(formValues.phoneNumber));
                                        }}
                                        errors={!isValidPhoneNumber ? [translate('global.error.invalid_phone_number')] : []}
                                    />
                                </Fragment>
                            );
                        }}
                    </Form>
                    <FormButtons
                        formName={formStepNames.SUMMARY}
                        handleOnClose={() => handleOnClose(formValues)}
                        isSubmitDisabled={!isValidEmail}
                    />
                </Fragment>
            )}
        </Form>
    );
};

SummaryScreen.propTypes = {
    invoiceSubmissionDraft: PropTypes.object,
    memberInfo: PropTypes.object,
    submitInvoiceSubmission: PropTypes.func.isRequired,
    storeInvoiceSubmissionDraft: PropTypes.func.isRequired,
    cancelInvoiceSubmissionDraft: PropTypes.func.isRequired,
    initiateDeleteInvoiceSubmissionServiceFlow: PropTypes.func.isRequired,
};

SummaryScreen.defaultProps = {
    invoiceSubmissionDraft: null,
    memberInfo: null,
};

const mapStateToProps = state => {
    return {
        invoiceSubmissionDraft: state.invoiceSubmissions.invoiceSubmissionDraft,
        memberInfo: state.member.memberInfo,
    };
};

const mapDispatchToProps = dispatch => ({
    submitInvoiceSubmission: payload => dispatch({
        type: invoiceSubmissionActionTypes.SUBMIT_INVOICE_SUBMISSION,
        payload,
    }),
    storeInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_DRAFT,
        payload,
    }),
    cancelInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.INITIATE_INVOICE_SUBMISSION_CANCEL_FLOW,
        payload,
    }),
    initiateDeleteInvoiceSubmissionServiceFlow: payload => dispatch({
        type: invoiceSubmissionActionTypes.INITIATE_DELETE_INVOICE_SUBMISSION_SERVICE_FLOW,
        payload,
    }),
});


export default connect(mapStateToProps, mapDispatchToProps)(SummaryScreen);
