export const invoiceSubmissionServiceTypes = {
    TOWING: 'TOWING',
    RECOVERY: 'RECOVERY',
    ROADSIDE_ASSISTANCE: 'ROADSIDE_ASSISTANCE',
    VEHICLE_OPENING: 'VEHICLE_OPENING',
    NO_SERVICE: 'NO_SERVICE',
    ACCOMMODATION: 'ACCOMMODATION',
    FURTHER_JOURNEY: 'FURTHER_JOURNEY',
    SHORT_TRIP: 'SHORT_TRIP',
    RENTAL_CAR: 'RENTAL_CAR',
    BROKEN_GLASS: 'BROKEN_GLASS',
    MARTEN_BITE: 'MARTEN_BITE',
    ROADKILL: 'ROADKILL',
    ELEMENTAL_DAMAGE: 'ELEMENTAL_DAMAGE',
    OTHER: 'OTHER',
};
