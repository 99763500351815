import {take, put, select, fork} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as invoiceSubmissionActionTypes from './invoiceSubmissionActionTypes';
import {mafInvoiceSubmissionStatuses} from '../mia-entities/invoice-submission';
import invoiceSubmissionTabs from './constants/invoiceSubmissionTabs';
import * as serviceCaseActionTypes from '../service-case/serviceCaseActionTypes';
import invoiceSubmissionFiles from './invoiceSubmissionFiles';
import routePaths from '../routePaths';

const activeStatuses = [mafInvoiceSubmissionStatuses.CREDITING_REQUESTED, mafInvoiceSubmissionStatuses.PROCESSING];
const inactiveStatuses = [
    mafInvoiceSubmissionStatuses.FINISHED,
    mafInvoiceSubmissionStatuses.DENIED,
    mafInvoiceSubmissionStatuses.TOTAL_REFUND,
    mafInvoiceSubmissionStatuses.PARTIAL_REFUND,
];

export const loadInvoiceSubmission = function* loadInvoiceSubmission({payload}) {
    const {invoiceSubmissionId} = payload.match.params;
    const {invoiceSubmissionDraft} = yield select(state => state.invoiceSubmissions);
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');

    if (!invoiceSubmissionId || !!invoiceSubmissionDraft) return;

    yield fork(fetchRequest,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_REQUEST,
        miaService.getInvoiceSubmission, {
            invoiceSubmissionId,
        });

    const actionResult = yield take([
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_REQUEST_SUCCEEDED,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_REQUEST_FAILED,
    ]);

    if (actionResult.error) return;

    const {response} = actionResult.payload;
    yield put({
        type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_DRAFT,
        payload: {invoiceSubmissionDraft: response},
    });
};

export const loadInvoiceSubmissions = function* ({payload}) {
    const {match} = payload;
    const {activeTab} = match.params;
    const memberData = JSON.parse(window.sessionStorage.getItem('userData'));
    const {membershipNo} = memberData;
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');
    yield put({
        type: serviceCaseActionTypes.STORE_REQUEST_STATE,
        payload: {isLoading: true},
    });

    if (invoiceSubmissionTabs.ACTIVE === activeTab) {
        yield fork(loadActiveInvoiceSubmissions, {
            membershipNumber: membershipNo,
            statuses: activeStatuses,
            miaService,
        });
        return;
    }
    yield fork(loadInactiveInvoiceSubmissions, {
        membershipNumber: membershipNo,
        statuses: inactiveStatuses,
        miaService,
    });
};

const loadActiveInvoiceSubmissions = function* loadActiveInvoiceSubmissions({membershipNumber, statuses, miaService}) {
    yield put({
        type: invoiceSubmissionActionTypes.STORE_ACTIVE_INVOICE_SUBMISSIONS,
        payload: {invoiceSubmissionDTOs: null},
    });

    yield fork(fetchRequest, invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST,
        miaService.getInvoiceSubmissions, {membershipNumber, statuses});

    const actionResult = yield take([
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        const invoiceSubmissionsDTO = actionResult.payload.response.data;
        yield put({
            type: invoiceSubmissionActionTypes.STORE_ACTIVE_INVOICE_SUBMISSIONS,
            payload: {invoiceSubmissionsDTO},
        });
        yield put({
            type: serviceCaseActionTypes.STORE_REQUEST_STATE,
            payload: {isLoading: false},
        });
    }
};

// eslint-disable-next-line
const loadInactiveInvoiceSubmissions = function* loadInactiveInvoiceSubmissions({membershipNumber, statuses, miaService}) {
    yield put({
        type: invoiceSubmissionActionTypes.STORE_INACTIVE_INVOICE_SUBMISSIONS,
        payload: {invoiceSubmissionDTOs: null},
    });

    yield fork(fetchRequest, invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST,
        miaService.getInvoiceSubmissions, {membershipNumber, statuses});

    const actionResult = yield take([
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSIONS_REQUEST_FAILED,
    ]);

    if (!actionResult.error) {
        const invoiceSubmissionsDTO = actionResult.payload.response.data;
        yield put({
            type: invoiceSubmissionActionTypes.STORE_INACTIVE_INVOICE_SUBMISSIONS,
            payload: {invoiceSubmissionsDTO},
        });
        yield put({
            type: serviceCaseActionTypes.STORE_REQUEST_STATE,
            payload: {isLoading: false},
        });
    }
};

export const loadInvoiceSubmissionDraft = function* loadInvoiceSubmissionDraft({payload}) {
    const memberData = JSON.parse(window.sessionStorage.getItem('userData'));
    const {membershipNo} = memberData;
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');
    const {match} = payload;
    const {path} = match;
    const {invoiceSubmissionDraft} = yield select(state => state.invoiceSubmissions);

    if (path === routePaths.SERVICE_CASES && !!invoiceSubmissionDraft) return;

    yield put({
        type: invoiceSubmissionFiles.actionTypes.STORE_FILES,
        payload: {files: []},
    });

    yield fork(fetchRequest,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_DRAFT_REQUEST,
        miaService.getInvoiceSubmissions, {
            membershipNumber: membershipNo,
            statuses: mafInvoiceSubmissionStatuses.NEW,
        });

    const resultAction = yield take([
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_DRAFT_REQUEST_SUCCEEDED,
        invoiceSubmissionActionTypes.GET_INVOICE_SUBMISSION_DRAFT_REQUEST_FAILED,
    ]);

    if (resultAction.error) return;

    const invoiceSubmissionsDTO = resultAction.payload.response.data;
    yield put({
        type: invoiceSubmissionActionTypes.STORE_INVOICE_SUBMISSION_DRAFT,
        payload: {invoiceSubmissionDraft: invoiceSubmissionsDTO[0]},
    });
};
