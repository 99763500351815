import {fork, put, select, take} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';

const fetchAidServiceMaxPrices = function* fetchAidServiceMaxPrices() {
    const {serviceManager} = yield select(state => state.application);
    const miaService = serviceManager.loadService('miaService');

    while (true) {
        const {payload} = yield take(invoiceSubmissionActionTypes.FETCH_AID_SERVICE_MAX_PRICE);
        const {validOn, aidServiceType} = payload;
        const {memberInfo} = yield select(state => state.member);
        const memberStatus = memberInfo?.status;

        yield fork(fetchRequest, invoiceSubmissionActionTypes.FETCH_AID_SERVICE_MAX_PRICE_REQUEST,
            miaService.getAidMaxPrice, {validOn, memberStatus, aidServiceType});

        const responseAction = yield take([
            invoiceSubmissionActionTypes.FETCH_AID_SERVICE_MAX_PRICE_REQUEST_SUCCEEDED,
            invoiceSubmissionActionTypes.FETCH_AID_SERVICE_MAX_PRICE_REQUEST_FAILED,
        ]);

        if (responseAction.error) continue;

        const {response} = responseAction.payload;

        if (!response) continue;

        yield put({
            type: invoiceSubmissionActionTypes.STORE_AID_MAX_PRICE,
            payload: {aidMaxPriceDTO: response},
        });
    }
};

export default fetchAidServiceMaxPrices;
