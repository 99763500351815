/**
 * App config
 */

const {
    MIA_SERVICE_URL,
    DEPLOYMENT_ENV,
    DATADOG_RUM_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE,
    DATADOG_ENABLE,
} = process.env;

export default {
    // General
    VERSION: process.env.APP_VERSION,
    BUILD: process.env.APP_BUILD,
    PUBLIC_URL: process.env.PUBLIC_URL || '/',
    LOCALE: process.env.LOCALE || 'de',
    MIA_SERVICE_URL,
    DEPLOYMENT_ENV,
    DATADOG_RUM_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE,
    DATADOG_ENABLE,
    initialResubmitCount: -1,
    defaultServiceCasesPage: 1,
    defaultServiceCasesPageSize: 10,
    defaultNumberOfFirstPages: 2,
    defaultNumberOfLastPages: 2,
    MIA_LOCALE_TRANSLATION_EN_US_URL: process.env.MIA_LOCALE_TRANSLATION_EN_US_URL,
    MIA_LOCALE_TRANSLATION_DE_DE_URL: process.env.MIA_LOCALE_TRANSLATION_DE_DE_URL,
    minRegistrationDate: '1.1.1900',
    GERMANY_BOUNDING_BOX: {
        east: 15,
        north: 55,
        south: 47,
        west: 5,
    },
};
