import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Option, AutocompleteField} from '../../ui-components';
import config from '../../config';

const SearchAutocomplete = props => {
    const [listItems, setListItems] = useState([]);


    useEffect(() => {
        if (props.value) {
            const storeSuggestions = function (predictions, status) {
                if (!predictions) {
                    return;
                }
                let count = 0;
                const streets = [];

                predictions.forEach(prediction => {
                    if (count > 5) return;
                    streets.push({value: prediction.description, id: prediction.place_id});
                    count += 1;
                });

                setListItems(streets);
            };

            const autocompleteConfig = {
                input: props.value,
                types: ['address'],
                fields: ['address_components'],
                locationRestriction: config.GERMANY_BOUNDING_BOX,
            };

            const autocomplete = new window.google.maps.places.AutocompleteService();
            autocomplete.getQueryPredictions(autocompleteConfig, storeSuggestions);
        }
    }, [props.value]);

    return (
        <AutocompleteField
            {...props}
        >
            {listItems.map((listItem, idx) => {
                return <Option name={listItem.value} value={listItem.value} key={`${listItem.id}-${idx}`}>{listItem.value}</Option>;
            })
            }
        </AutocompleteField>
    );
};

SearchAutocomplete.propTypes = {
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
    value: PropTypes.string,
    name: PropTypes.string,
    additionalAttributes: PropTypes.object,
    errors: PropTypes.arrayOf(PropTypes.string), // PropTypes.object,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,

};

SearchAutocomplete.defaultProps = {
    label: '',
    isDisabled: false,
    value: '',
    name: '',
    additionalAttributes: {},
    errors: [],
    onChange: () => {},
    onFocus: () => {},
};

export default SearchAutocomplete;
