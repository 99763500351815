import routePaths from './routePaths';
import ApplicationScreen from './application/ApplicationScreen';
import NotFoundScreen from './application/NotFoundScreen';
import DashboardScreen from './application/DashboardScreen';
import LoginScreen from './auth/LoginScreen';
import * as authLoaderSagas from './auth/authLoaderSagas';
import {userState} from './auth/constants/userState';
import InvoiceSubmissionCreationView from './invoice-submission/InvoiceSubmissionCreationView';
import ServiceCasesScreen from './service-case/ServiceCasesScreen';
import * as serviceCaseLoaderSagas from './service-case/serviceCaseLoaderSagas';
import * as memberLoaderSagas from './member/memberLoaderSagas';
import * as invoiceSubmissionLoaderSagas from './invoice-submission/invoiceSubmissionLoaderSagas';
import InvoiceSubmissionsScreen from './invoice-submission/InvoiceSubmissionsScreen';
import Maintenance from './application/Maintenance';

/**
 * Location object definition (react-router, history)
 *
 * @typedef {Object} Location
 * @property {string} key - key is available for all by HashHistory
 * @property {string} pathname - path part of route
 * @property {string} search - search query part of route
 * @property {string} hash - hash (#) part of route
 * @property {Object} state - user defined state for the route
 */

/**
 * ConnectedRoute object definition (react-router-config, connected-react-router, redux-saga)
 *
 * @typedef {Object} ConnectedRoute
 * @property {string} path - any valid URL path that path-to-regexp understands.
 * @property {?Object} component - React component for application screen
 * @property {?function} render - render prop function
 * @property {?Location} location - for matching against different location than one in history
 * @property {?boolean} exact - when true, will only match if the path matches the location.pathname exactly
 * @property {?boolean} string - when true, a path that has a trailing slash will only match a location.pathname
 *                      with a trailing slash
 * @property {?boolean} sensitive - when true, will match if the path is invoice-submission sensitive.
 * @property {?Array<ConnectedRoute>} routes - sub-routes
 * @property {?Array<Array<[saga, Object]>>} locationChangeSideEffects - Redux sagas (side-effects) to be run
 *                                            with payload object
 */

/**
 * Static route configuration
 *
 * @returns {Array<ConnectedRoute>}
 */
const getRoutes = () => [{
    component: ApplicationScreen,
    locationChangeSideEffects: [],
    routes: [
        {
            path: routePaths.DASHBOARD,
            exact: true,
            component: DashboardScreen,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.LOGIN,
                }],
                [invoiceSubmissionLoaderSagas.loadInvoiceSubmissionDraft],
            ],
        },
        window.DYNAMIC_ENV.IS_MAINTENANCE_MODE_ON
        && window.location.hostname !== 'mia-bd.ace-ms.de'
        && window.location.hostname !== 'mia-bd.dev.ace-ecs.computerrock.com'
        && window.location.hostname !== 'mia-bd.int.ace-ecs.computerrock.com'
        && window.location.hostname !== 'mia-bd.pp.ecs-nonprod.de'
        && window.location.hostname !== 'mia-bd.pp2.ecs-nonprod.de'
            ? {
                path: '*',
                component: Maintenance,
            } : {
                path: routePaths.LOGIN,
                exact: true,
                component: LoginScreen,
                locationChangeSideEffects: [
                    [authLoaderSagas.accessControl, {
                        allowedState: userState.USER_SIGNED_OUT,
                        redirectTo: routePaths.DASHBOARD,
                    }],
                ],
            },
        {
            path: routePaths.NEW_INVOICE_SUBMISSION_CREATION,
            exact: true,
            component: InvoiceSubmissionCreationView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.LOGIN,
                }],
                [memberLoaderSagas.getMemberInfo],
                [serviceCaseLoaderSagas.loadNewServiceCase],
                [invoiceSubmissionLoaderSagas.loadInvoiceSubmission],
            ],
        },
        {
            path: routePaths.INVOICE_SUBMISSION_CREATION,
            exact: true,
            component: InvoiceSubmissionCreationView,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.LOGIN,
                }],
                [serviceCaseLoaderSagas.loadExistingServiceCase],
                [memberLoaderSagas.getMemberInfo],
                [invoiceSubmissionLoaderSagas.loadInvoiceSubmission],
            ],
        },
        {
            path: routePaths.INVOICE_SUBMISSIONS,
            exact: true,
            component: InvoiceSubmissionsScreen,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.LOGIN,
                }],
                [invoiceSubmissionLoaderSagas.loadInvoiceSubmissions],
            ],
        },
        {
            path: routePaths.SERVICE_CASES,
            exact: true,
            component: ServiceCasesScreen,
            locationChangeSideEffects: [
                [authLoaderSagas.accessControl, {
                    allowedState: userState.USER_SIGNED_IN,
                    redirectTo: routePaths.LOGIN,
                }],
                [serviceCaseLoaderSagas.loadServiceCases],
                [invoiceSubmissionLoaderSagas.loadInvoiceSubmissionDraft],
            ],
        },
        {
            path: '*',
            component: NotFoundScreen,
        },
    ],
}];

export default getRoutes;
